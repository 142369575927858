import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const ImageModal = ({ show, img, name, setShowModal, onHide }) => {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Proof of payment for {name}
        </Modal.Title>
        <div style={{ fontSize: "20px", cursor: "pointer" }} onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <img src={img} style={{ width: "100%" }} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
