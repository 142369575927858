import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelectAdmin, login } from "../features/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const Signin = () => {
  const admin = useSelector(authSelectAdmin);

  const [form, setForm] = useState({});

  const handleForm = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  };

  const dispatch = useDispatch();

  //parsers
  const isMissing = !form.name || !form.password;

  const navigate = useNavigate();

  useEffect(() => {
    if (admin.token) {
      navigate("/");
    }
  }, [admin, navigate]);

  return (
    <div className="signin">
      <div className="signin__card">
        <div className="signin__header">
          <span>admin</span>
        </div>
        <div className="signin__body">
          <p>enter your username and password</p>
          <input
            name="name"
            type="text"
            placeholder="Usernane"
            onChange={handleForm}
          />
          <input
            name="password"
            type="password"
            placeholder="password"
            onChange={handleForm}
          />
          <button
            onClick={() => {
              if (!isMissing) {
                const myPromise = dispatch(login(form));
                toast.promise(
                  myPromise,
                  {
                    loading: "logging in...",
                    success: (data) => {
                      return data?.payload?.err
                        ? `${data?.payload?.err}`
                        : "Login successful!";
                    },
                    error: "Error occured!",
                  },
                  {
                    loading: { duration: Infinity },
                    success: { duration: 3000 },
                    error: { duration: 3000 },
                  }
                );
              }
            }}
          >
            sign in
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signin;
