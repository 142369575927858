import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/appSlice";
import { appApi } from "../services/app";
import authReducer from "../features/authSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      appApi.middleware
    ),
});
