import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../constants/variables";

const initialState = {
  loading: { id: "", state: false },
  success: { id: "", msg: "" },
  error: { id: "", err: "" },
  admin: localStorage.getItem("adminData")
    ? JSON.parse(localStorage.getItem("adminData"))
    : {},
};

export const register = createAsyncThunk(
  "auth/register",
  async (form, { dispatch, getState }) => {
    try {
      const { data } = await axios.post(`${url}/admin/register`, form);
      return data;
    } catch (err) {
      return { id: "admin/register", err: err.message };
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (form, { dispatch, getState }) => {
    try {
      const { data } = await axios.post(`${url}/admin/login`, form);
      return data;
    } catch (err) {
      return {
        id: "admin/login",
        err: err?.response?.data?.err ?? err.message,
      };
    }
  }
);
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authUpdateLoading(state, action) {
      state.loading = action.payload;
    },
    authUpdateSuccess(state, action) {
      state.success = action.payload;
    },
    authUpdateError(state, action) {
      state.error = action.payload;
    },
    logout(state, action) {
      state.admin = {};
      localStorage.removeItem("adminData");
    },
  },
  extraReducers(builder) {
    builder
      .addCase(register.pending, (state, action) => {
        state.error = {};
        state.success = {};
        state.loading = { id: "admin/register", state: true };
      })
      .addCase(register.fulfilled, (state, action) => {
        if (action.payload?.err) {
          state.error = { id: "", err: action.payload.error };
          state.success = {};
          state.loading = {};
        } else if (action.payload.token) {
          state.error = {};
          state.success = {
            id: "admin/register",
            msg: "registered successfully",
          };
          state.loading = {};
          state.admin = action.payload;
          localStorage.setItem("adminData", JSON.stringify(action.payload));
        }
      })
      .addCase(register.rejected, (state, action) => {
        state.error = action.payload;
        state.success = {};
        state.loading = {};
      })
      .addCase(login.pending, (state, action) => {
        state.error = {};
        state.success = {};
        state.loading = { id: "admin/login", state: true };
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload?.err) {
          state.error = { id: "", err: action.payload.err };
          state.success = {};
          state.loading = {};
        } else if (action.payload.token) {
          state.error = {};
          state.success = {
            id: "admin/login",
            msg: "logged in successfully",
          };
          state.loading = {};
          state.admin = action.payload;
          localStorage.setItem("adminData", JSON.stringify(action.payload));
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload;
        state.success = {};
        state.loading = {};
      });
  },
});

export const authSelectLoading = (state) => state.auth.loading;
export const authSelectSuccess = (state) => state.auth.success;
export const authSelectError = (state) => state.auth.error;
export const authSelectAdmin = (state) => state.auth.admin;
export const { authUpdateLoading, authUpdateSuccess, authUpdateError, logout } =
  authSlice.actions;
export default authSlice.reducer;
