import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialReactTable from "material-react-table";
import { authSelectAdmin, logout } from "../features/authSlice";
import { ThemeProvider, createTheme } from "@mui/material";
import { theme } from "../config/themes";
import { IoPerson } from "react-icons/io5";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import {
  appSelectRegList,
  approve,
  fetchRegList,
  reject,
} from "../features/appSlice";
import { toast } from "react-hot-toast";
import ImageModal from "../components/ImageModal";

const Homepage = () => {
  const admin = useSelector(authSelectAdmin);
  const regList = useSelector(appSelectRegList);
  const [partyNo, setPartyNo] = useState("");
  const [img, setImg] = useState("");
  const [name, setName] = useState("");
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRegList());
  }, [dispatch]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Full Name",
      },
      {
        accessorKey: "sex",
        header: "Gender",
      },
      {
        accessorKey: "phone", //normal accessorKey
        header: "Phone",
      },
      {
        accessorKey: "lga",
        header: "LGA",
      },
      {
        accessorKey: "ward",
        header: "Ward",
      },
      {
        accessorKey: "topping",
        header: "Member",
      },
      {
        accessorKey: "partyNo",
        header: "Party ID",
        enableClickToCopy: true,
      },
      {
        accessorKey: "status",
        header: "Membership",
      },
      {
        accessorKey: "payment",
        header: "Payment",
        Cell: ({ cell, column }) =>
          cell.getValue().url === "paystack" ? (
            "Paystack payment"
          ) : cell.getValue().url === "none" ? (
            "No payment"
          ) : (
            <img
              src={cell.getValue().url}
              style={{ cursor: "pointer", width: "50px", height: "50px" }}
              onClick={() => {
                setImg(cell.getValue().url);
                setName(cell.getValue().name);
                setShowModal(true);
              }}
            />
          ),
      },
    ],
    []
  );

  const tableTheme = useMemo(() => createTheme(theme), []);

  return (
    <div className="homepage">
      <ImageModal
        img={img}
        name={name}
        show={showModal}
        setShowModal={setShowModal}
        onHide={() => {
          setShowModal(false);
        }}
      />
      <div className="homepage__details">
        <div className="homepage__account">
          <IoPerson size={30} />
          <h3>{admin.name}</h3>
        </div>
        <div className="homepage__actions">
          <input
            placeholder="Input party ID"
            value={partyNo}
            onChange={(e) => {
              setPartyNo(e.target.value);
            }}
          />
          <BsFillCheckSquareFill
            className="homepage__icons"
            color="green"
            size={30}
            onClick={() => {
              const myPromise = dispatch(approve(partyNo));
              toast.promise(myPromise, {
                loading: "Approving member...",
                success: "Member approved!",
                error: "Error occured!",
              });
              setPartyNo("");
            }}
          />
          <AiFillCloseSquare
            className="homepage__icons"
            color="red"
            size={37}
            onClick={() => {
              const myPromise = dispatch(reject(partyNo));
              toast.promise(myPromise, {
                loading: "Rejecting member...",
                success: "Member rejected!",
                error: "Error occured!",
              });
              setPartyNo("");
            }}
          />
        </div>
        <button
          onClick={() => {
            dispatch(logout());
          }}
        >
          logout
        </button>
      </div>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable columns={columns} data={regList} />
      </ThemeProvider>
    </div>
  );
};

export default Homepage;
