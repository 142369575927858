import {
  RouterProvider,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./scss/main.scss";
import Layout from "./components/Layout";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Homepage from "./pages/Homepage";
import Signin from "./pages/Signin";
// import Signup from "./pages/Signup";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="/signin" element={<Signin />} />
      {/* <Route path="/signup" element={<Signup />} /> */}
      <Route element={<ProtectedRoutes />}>
        <Route index element={<Homepage />} />
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
