import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../constants/variables";

const initialState = {
  loading: { id: "", state: "" },
  success: { id: "", msg: "" },
  error: { id: "", err: "" },
  regList: [],
};

export const fetchRegList = createAsyncThunk(
  "app/regList",
  async (emptyArg, { dispatch, getState }) => {
    try {
      const { token } = getState().auth.admin;
      const { data } = await axios.post(`${url}/admin/reglist`, emptyArg, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      return { id: "admin/reglist", err: err.message };
    }
  }
);

export const approve = createAsyncThunk(
  "app/approve",
  async (partyNo, { dispatch, getState }) => {
    try {
      const { token } = getState().auth.admin;
      const { data } = await axios.post(
        `${url}/admin/approve/${partyNo}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch(fetchRegList());
      return data;
    } catch (err) {
      return { id: "admin/approve", err: err.message };
    }
  }
);

export const reject = createAsyncThunk(
  "app/reject",
  async (partyNo, { dispatch, getState }) => {
    try {
      const { token } = getState().auth.admin;
      const { data } = await axios.post(
        `${url}/admin/reject/${partyNo}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch(fetchRegList());
      return data;
    } catch (err) {
      return { id: "admin/reject", err: err.message };
    }
  }
);

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    appUpdateLoading(state, action) {
      state.loading = action.payload;
    },
    appUpdateSuccess(state, action) {
      state.success = action.payload;
    },
    appUpdateError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchRegList.fulfilled, (state, action) => {
      state.regList = action.payload;
    });
  },
});

export const appSelectLoading = (state) => state.app.loading;
export const appSelectSuccess = (state) => state.app.success;
export const appSelectError = (state) => state.app.error;
export const appSelectRegList = (state) => state.app.regList;
export const { appUpdateLoading, appUpdateSuccess, appUpdateError } =
  appSlice.actions;
export default appSlice.reducer;
