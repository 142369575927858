import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { authSelectAdmin } from "../features/authSlice";
import { useSelector } from "react-redux";

const ProtectedRoutes = () => {
  const admin = useSelector(authSelectAdmin);
  return admin.token ? <Outlet /> : <Navigate to="/signin" />;
};

export default ProtectedRoutes;
