export const theme = {
  palette: {
    mode: "light", //let's use the same dark/light mode as the global theme
    primary: {
      main: "#2d3e50",
    },
    secondary: {
      main: "#2d3e50",
    },
    error: {
      main: "#fd4a49",
    },
    info: {
      main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
      dark: "#2d3e50",
    },
  },
  typography: {
    button: {
      textTransform: "none", //customize typography styles for all buttons in table by default
      color: "#2d3e50",
      // fontSize: "1.9rem",
    },
    htmlFontSize: 10,
    fontSize: 12,
    body1: {
      fontSize: 12,
    },
    body2: {
      fontSize: 12,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12, //override to make tooltip font size larger
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          color: "#2d3e50",
        },
      },
    },
  },
};

export const customerTableThemeObj1 = {
  palette: {
    mode: "light", //let's use the same dark/light mode as the global theme
    primary: {
      main: "#2d3e50",
    },
    secondary: {
      main: "#2d3e50",
    },
    info: {
      main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
      dark: "#2d3e50",
    },
  },
  typography: {
    button: {
      textTransform: "none", //customize typography styles for all buttons in table by default
      color: "#2d3e50",
      // fontSize: "1.9rem",
    },
    htmlFontSize: 10,
    fontSize: 12,
    body1: {
      fontSize: 12,
      marginTop: 10,
      marginBottom: -10,
    },
    body2: {
      fontSize: 8,
      backgroundColor: "red",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1.3rem", //override to make tooltip font size larger
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
        },
      },
    },
  },
};
